export const showLoader = () => {
	const loader = document.querySelector('.am-preloader')
	loader.classList.add('am-preloader_show')
}

export const hideLoader = () => {
	const loader = document.querySelector('.am-preloader')
	loader.classList.remove('am-preloader_show')
}

document.showLoader = showLoader;
document.hideLoader = hideLoader;
