// Cursor zoom

document.addEventListener('DOMContentLoaded', function() {
	const cards = document.querySelectorAll('.am-card__img-wrap');

	if (cards.length > 0) {
		zoomElem = document.querySelector('.am-circle-cursor');
		if (!zoomElem) 
			return false;
		
		
		cards.forEach(card => {
			if (window.innerWidth > 779) {
				card.addEventListener('mouseenter', function(e) {
					zoomElem.classList.add('am-circle-cursor_visible');
					setCursorPositionToElem(zoomElem, e);
				});
			
				card.addEventListener('mousemove', function(e) {
					setCursorPositionToElem(zoomElem, e);
				});
			
				card.addEventListener('mouseleave', function(e) {
					zoomElem.classList.remove('am-circle-cursor_visible');
					setCursorPositionToElem(zoomElem, e);
				});
			}
		});
	}
});


function setCursorPositionToElem(elem, e, container = document.body) {
	const rect = container.getBoundingClientRect();
	const cursorX = e.clientX - rect.left;
	const cursorY = e.clientY - rect.top;
	
	elem.style.top = `${cursorY}px`;
	elem.style.left = `${cursorX}px`;
}