import Swiper from '../../node_modules/swiper/js/swiper.js'

const mainClass = 'am-slider-half';

document.addEventListener('DOMContentLoaded', function() {
	const sliders = document.querySelectorAll(`.${mainClass}`); 
	if (sliders.length == 0)
		return false;

	sliders.forEach(slider => {

		const slides = slider.querySelectorAll(`.${mainClass}__slide`);

		if (slides.length == 1) {
			slider.classList.add(`${mainClass}_single`);
			return false
		}

		const sliderHalf = new Swiper(slider.querySelector('.swiper-container'), {
			spaceBetween: 20,
			slidesPerView: 1,
			speed: 450,
			simulateTouch: false,
			loop: true,
			slideActiveClass: `${mainClass}__slide_active`,
			slideDuplicateActiveClass: `${mainClass}__slide_active`,
			autoHeight: true,
			// autoplay: {
			// 	delay: 5000
			// },
			navigation: {
				nextEl: slider.querySelector('.swiper-arrow-right'),
				prevEl: slider.querySelector('.swiper-arrow-left'),
			},
			pagination: {
				el: `.${mainClass} .bullets`,
				type: 'bullets',
				bulletClass: 'bullet',
				bulletActiveClass: 'bullet_active',
				clickable: true,
			},
			breakpoints: {
				1280: {
					autoHeight: false,
				}
			}
		});

		sliderHalf.on('slideChange', function() {
			const mediaItems = slider.querySelectorAll(`.${mainClass}__media-item`);
			mediaItems.forEach(item => item.classList.remove(`${mainClass}__media-item_active`));

			mediaItems[sliderHalf.realIndex].classList.add(`${mainClass}__media-item_active`);
		});
	})
});

