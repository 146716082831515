import Swiper from '../../node_modules/swiper/js/swiper.js'
import {play, pause} from "./video-functions";

document.addEventListener('DOMContentLoaded', function () {
	if (document.querySelector('.am-slider')) {
		document.querySelectorAll('.am-slider').forEach(slider => {
			const thumbsElem = slider.querySelector('.am-slider__thumbs')
			const galleryElem = slider.querySelector('.am-slider__main')
			const btnNext = slider.querySelector('.am-slider-nav__button_next')
			const btnPrev = slider.querySelector('.am-slider-nav__button_prev')
			const paginationElem = slider.querySelector('.am-slider-pagination')

			const galleryThumbs = new Swiper(thumbsElem, {
				spaceBetween: 9,
				slidesPerView: 8,
				freeMode: true,
				watchSlidesVisibility: true,
				watchSlidesProgress: true,
				scrollbar: {
					el: '.am-slider__scrollbar',
					draggable: true,
					dragClass: 'am-slider__scrollbar-drag'
				},
				breakpoints: {
					320: {
						slidesPerView: 4,
					},
					780: {
						slidesPerView: 5,
					},
					992: {
						slidesPerView: 6,
					},
					1280: {
						slidesPerView: 7,
					},
					1600: {
						slidesPerView: 8,
					},
				}
			});

			const galleryTop = new Swiper(galleryElem, {
				spaceBetween: 10,
				slidesPerView: 1,
				navigation: {
					nextEl: btnNext,
					prevEl: btnPrev,
					disabledClass: 'am-slider-nav__button_disable'
				},
				thumbs: {
					swiper: galleryThumbs
				},
				pagination: {
					el: paginationElem,
					type: 'fraction',
					currentClass: 'am-slider-pagination__current',
					totalClass: 'am-slider-pagination__total',
					renderFraction: function (currentClass, totalClass) {
						return '<span class="' + currentClass + '"></span>' +
							'<span>&nbsp/&nbsp</span>' +
							'<span class="' + totalClass + '"></span>';
					}
				},
			});

			galleryTop.on('slideChangeTransitionStart', function () {
				const lastSlide = galleryTop.slides[galleryTop.previousIndex];
				if (lastSlide.querySelector('.am-slider-item__video')) {
					const video = lastSlide.querySelector('.am-slider-item__video');

					pause(video);
				}
			})
		})
	}
})

window.onload = function () {
	if (document.querySelector('.am-slider-item_video')) {
		document.querySelectorAll('.am-slider-item_video').forEach(item => {
			item.addEventListener('click', function () {
				const video = item.querySelector('.am-slider-item__video');

				play(video);
				video.classList.add('am-slider-item__video_visible');
			})
		})
	}
}