import { hideLoader, showLoader } from '../preloader';

const URL = '/ajax/medialibrary/getDetailAlbum.php?id';

export default class Media {
	static getDetailAlbum(id) {
	
		return fetch(`${URL}=${id}`)
			.then(res => res.json());
	}
}