const tag = document.createElement('script');

tag.src = "https://www.youtube.com/iframe_api";
const firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

const youtubeContainers = document.querySelectorAll('.youtube-video');
const youtubePlayers = [];

if (youtubeContainers) {
  window.onYouTubePlayerAPIReady = function () {
    initPlayers(youtubeContainers)
  }
}

export const initPlayers = (videos, params = {}) => {
  videos.forEach((item) => {
    const videoId = item.dataset.videoId;

    if (!getYPByID(videoId)) {
      const player = new YT.Player(item, {
        height: '360',
        width: '640',
        videoId,
        ...params
      });
  
      player.amPlayerId = videoId;
      player.amType = 'youtube';
      youtubePlayers.push(player);
      console.log('YT', youtubePlayers);
    }
  });
}

export const getYPByID = (id) => (
  youtubePlayers.filter((item) => item.amPlayerId === id)[0]
);

