export function mediaGalleryBaseTemplate(galleryId) {
	return `
		<div class="am-media" id="${galleryId}">
			<button class="am-media__close"></button>
			<div class="am-media__content">
				<div class="am-media__slider swiper-container">
					<div class="swiper-wrapper">
						
					</div>
					<div class="am-media-zoom"><img src="./images/svg/zoom.svg" alt=""/></div>
				</div>
				<div class="am-media__bottom">
					<div class="am-media-progress"></div>
					<div class="am-media-info">
						<div class="am-media-info__slider swiper-container">
							<div class="swiper-wrapper">
							
<!--								<div class="swiper-slide am-media-info__item">-->
<!--									<div class="am-media-info__top"><span class="am-media-info__date">30 августа, 2020</span>-->
<!--										<div class="am-media-info__tags"><a class="am-tag am-media-info__tag" href=""># события</a><a-->
<!--											class="am-tag am-media-info__tag" href=""># видео</a></div>-->
<!--									</div>-->
<!--									<div class="am-media-info__title">Международный кинофестиваль «Лампа»</div>-->
<!--								</div>-->
<!--								<div class="swiper-slide am-media-info__item">-->
<!--									<div class="am-media-info__top"><span class="am-media-info__date">30 августа, 2020</span>-->
<!--										<div class="am-media-info__tags"><a class="am-tag am-media-info__tag" href=""># подкасты</a>-->
<!--										</div>-->
<!--									</div>-->
<!--									<div class="am-media-info__title">Международный кинофестиваль «Лампа» подкаст</div>-->
<!--								</div>-->
							</div>
						</div>
					</div>
					<div class="am-media-controls">
						<div class="am-media-controls__button am-media-controls__prev">
							<svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM21 7L1 7V9L21 9V7Z"
									fill="#FFCC00"/>
							</svg>
						</div>
						<div class="am-media-controls__button am-media-controls__next">
							<svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM21 7L1 7V9L21 9V7Z"
									fill="#FFCC00"/>
							</svg>
						</div>
						<div class="am-media-controls__pagination"></div>
					</div>
				</div>
			</div>
		</div>
	`;
}

export function mediaGalleryImageTemplate(src, alt = '') {
	return `
		<div class="swiper-slide am-media-slide">
			<div class="swiper-zoom-container">
				<img class="am-media-slide__img" src="${src}" alt="${alt}" draggable="false"/>
			</div>
		</div>
	`;
}

export function mediaGalleryVideoTemplate(id, type) {
	return `
		<div class="swiper-slide am-media-slide">
			<div class="am-media-slide__video ${type}-video" data-video-id="${id}" id="${id}"></div>
		</div>
	`;
}

export function mediaGalleryPodcastTemplate([imageSrc, audioSrc]) {
	return `
		<div class="swiper-slide am-media-slide am-media-slide_audio">
		<img class="am-media-slide__img" src="${imageSrc}" alt=""/>
			<audio src="${audioSrc}" preload="preload"></audio>
		</div>
	`;
}

export function mediaGalleryInfoTemplate(date, title) {
	return `
		<div class="swiper-slide am-media-info__item">
			<div class="am-media-info__top">
				<span class="am-media-info__date">${date}</span>
				<div class="am-media-info__tags"></div>
			</div>
			<div class="am-media-info__title">${title}</div>
		</div>
	`;
}

export function mediaGalleryTagTemplate(name, link) {
	return (`<a class="am-tag am-media-info__tag" href="${link}">#${name}</a>`);
}
