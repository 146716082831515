document.addEventListener('DOMContentLoaded', function() { 
	const drops = document.querySelectorAll('.am-drop'); 

	if (drops.length == 0) 
		return false 
	
	document.addEventListener('click', function(e) {
		if (e.target.closest('.am-drop')) {
			e.target.closest('.am-drop').classList.toggle('am-drop_visible');
		} else {
			drops.forEach(drop => drop.classList.remove('am-drop_visible'));
		}
	});
});