import Swiper from '../../node_modules/swiper/js/swiper.js'
import { getYPByID, initPlayers } from './youtubeApi'
import { play, pause } from "./video-functions";
import { hideLoader, showLoader } from './preloader'
import { disableBodyScroll, enableBodyScroll } from './scrollLock'
import {
	mediaGalleryBaseTemplate,
	mediaGalleryImageTemplate,
	mediaGalleryInfoTemplate,
	mediaGalleryTagTemplate,
	mediaGalleryVideoTemplate,
	mediaGalleryPodcastTemplate
} from "./mediaGalleryTemplates"
import get from "./params";
import Media from "./api/Media";
import {initVimeoPlayers} from "./vimeoApi";

document.addEventListener('DOMContentLoaded', function () {

	// Если в ссылке будет параметр
	if (get('element')) {
		showLoader();
		Media.getDetailAlbum(get('element'))
			.then(data => {
				loadMediaContent(data, null)
				hideLoader()
				removeElementParamInUrl()
			})
			.catch(err =>  {
				hideLoader()
				removeElementParamInUrl()
			});
	}

	document.body.addEventListener('click', function (e) {
		const mediaCard = e.target.closest('.am-card')
		if (mediaCard) {
			if (!mediaCard.hasAttribute('data-gallery'))
				return false

			const galleryId = mediaCard.dataset.gallery.slice(1)

			showLoader();

			Media.getDetailAlbum(galleryId)
				.then(data => {
					loadMediaContent(data, mediaCard)
					hideLoader()
				})
				.catch(err =>  {
					hideLoader()
				});
		}
	})

})

function removeElementParamInUrl() {
	history.replaceState && 
		history.replaceState(null, '', location.pathname + location.search.replace(/[\?&]element=[^&]+/, '')
			.replace(/^&/, '?'));
}

function loadMediaContent(data, element = null) {
	const galleryId = element?.dataset?.gallery || 'linkGallery';

	if (!document.querySelector(`#${galleryId}`))
		document.body.insertAdjacentHTML('beforeend', mediaGalleryBaseTemplate(galleryId))

	const gallery = document.querySelector(`#${galleryId}`)

	parseData(data, gallery)

	initMediaGallery(gallery)
	openMediaGallery(gallery)

	if (element) {
		element.addEventListener('click', function (e) {
			e.preventDefault()
			openMediaGallery(gallery)
		})
	}
}

document.loadMediaContent = loadMediaContent;

function parseData(data, gallery) {
	for (let key in data) {
		const topContainer = gallery.querySelector('.am-media__slider .swiper-wrapper');
		const bottomContainer = gallery.querySelector('.am-media-info .swiper-wrapper');

		// Обрабатываем изображения
		if (typeof data[key]?.src == 'string') {
			topContainer.insertAdjacentHTML('beforeend', mediaGalleryImageTemplate(data[key]?.src));
		}

		// Обрабатываем подкаст
		if (typeof data[key]?.src == 'object') {
			topContainer.insertAdjacentHTML('beforeend', mediaGalleryPodcastTemplate(data[key]?.src));
		}

		// Обрабатываем видео
		if (data[key].video_id && data[key].video_id != '') {
			topContainer.insertAdjacentHTML('beforeend', mediaGalleryVideoTemplate(data[key]?.video_id, data[key]?.video_type))
		}

		// Информация слайда
		if (data[key]?.title != '') {
			bottomContainer.insertAdjacentHTML('beforeend', mediaGalleryInfoTemplate(data[key].date, data[key].title))

			if (data[key].tags?.length > 0) {
				const lastItem = bottomContainer.querySelector('.swiper-slide:last-child')
				const tagsContainer = lastItem.querySelector('.am-media-info__tags')

				data[key].tags.forEach(item => {
					tagsContainer.insertAdjacentHTML('beforeend', mediaGalleryTagTemplate(item?.text, item?.link));
				})
			}
		}
	}
}

function initMediaGallery(gallery) {
	const mainSliderElem = gallery.querySelector('.am-media__slider')
	const secondSliderElem = gallery.querySelector('.am-media-info__slider')
	const progressBar = gallery.querySelector('.am-media-progress')
	const closeButton = gallery.querySelector('.am-media__close')
	const zoomableImages = mainSliderElem.querySelectorAll('.swiper-zoom-container img')
	const zoomElem = mainSliderElem.querySelector('.am-media-zoom')

	closeButton.addEventListener('click', () => closeMediaGallery(gallery))

	const mainSlider = new Swiper(mainSliderElem, {
		slidesPerView: 1,
		speed: 600,
		allowTouchMove: false,
		zoom: {
			toggle: false
		},
		effect: 'fade',
		fadeEffect: { crossFade: true },
		navigation: {
			nextEl: '.am-media-controls__next',
			prevEl: '.am-media-controls__prev',
			disabledClass: 'am-media-controls__button_disabled'
		},
		pagination: {
			el: '.am-media-controls__pagination',
			type: 'fraction',
			currentClass: 'am-media-controls__current',
			totalClass: 'am-media-controls__total',
			renderFraction: function (currentClass, totalClass) {
				return `<img class="am-media-controls__icon" src="/local/templates/lampa/images/svg/media-icon.svg">
								<div>
									<span class="${currentClass}"></span>
									<span>/</span>
									<span class="${totalClass}"></span>
								</div>`
			}
		}
	})

	const secondSlider = new Swiper(secondSliderElem, {
		slidesPerView: 1,
		allowTouchMove: false,
		speed: 0,
		effect: 'fade',
		fadeEffect: { crossFade: true },
	})

	mainSlider.on('slideNextTransitionStart', function () {
		secondSlider.slideNext()
	})

	mainSlider.on('slidePrevTransitionStart', function () {
		secondSlider.slidePrev()
	})

	mainSlider.on('slideChangeTransitionStart', function () {
		const lastSlide = mainSlider.slides[mainSlider.previousIndex]
		pauseMediaSlide(lastSlide, progressBar)
	})

	mainSlider.on('slideChangeTransitionEnd', function () {
		const curSlide = mainSlider.slides[mainSlider.activeIndex]
		setMediaSlideProgress(curSlide, progressBar)
	})

	// Zoom
	if (zoomableImages) {
		zoomableImages.forEach(image => {
			// Zoom img
			let flag = 0
			image.addEventListener("mousedown", function () {
				flag = 0
			}, false)

			image.addEventListener("mousemove", function () {
				flag = 1
			}, false)

			image.addEventListener("mouseup", function () {
				if (flag === 0) {
					mainSlider.zoom.toggle()
					if (window.innerWidth > 779) {
						if (!image.closest('.am-media-slide').classList.contains('swiper-slide-zoomed')) {
							zoomElem.classList.add('am-media-zoom_visible')
						} else {
							zoomElem.classList.remove('am-media-zoom_visible')
						}
					}
				}
			}, false)

			// Cursor zoom
			if (window.innerWidth > 779) {
				image.addEventListener('mouseenter', function(e) {
					zoomElem.classList.add('am-media-zoom_visible')
					setCursorPositionToElem(zoomElem, e, gallery)
				})
	
				image.addEventListener('mousemove', function(e) {
					setCursorPositionToElem(zoomElem, e, gallery)
				})
	
				image.addEventListener('mouseleave', function(e) {
					zoomElem.classList.remove('am-media-zoom_visible')
					setCursorPositionToElem(zoomElem, e, gallery)
				})
			}
		})
	}

	// Видео
	if (mainSliderElem.querySelector('.youtube-video')) {
		const videoElems = mainSliderElem.querySelectorAll('.youtube-video')
		initPlayers(videoElems, {
			controls: 0,
			disablekb: 1,
			enablejsapi: 1,
			iv_load_policy: 3,
			modestbranding: 1,
			rel: 1,
			events: {
				onStateChange: function (e) {
					if (e.data === 1) {
						progressBar.classList.add('am-media-progress_active')
						e.target.amInterval = setInterval(() => {
							progressBar.style.width = `${getPercentProgress(e.target)}%`
						}, 500)
					} else if (e.data === 0) {
						clearInterval(e.target.amInterval)
						progressBar.style.width = `100%`
					} else {
						clearInterval(e.target.amInterval)
						progressBar.classList.remove('am-media-progress_active')
					}
				}
			}
		})
	}

	if (mainSliderElem.querySelector('.vimeo-video')) {
		const vimeoVideoElems = mainSliderElem.querySelectorAll('.vimeo-video')
		initVimeoPlayers(vimeoVideoElems, {}, [
			{
				name: 'timeupdate',
				func: function (data) {
					progressBar.style.width = `${data.percent * 100}%`
				}
			},
			{
				name: 'pause',
				func: function () {
					progressBar.classList.remove('am-media-progress_active')
				}
			},
			{
				name: 'play',
				func: function () {
					progressBar.classList.add('am-media-progress_active')
				}
			},
		])
	}

	// Аудио
	if (mainSliderElem.querySelector('.am-media-slide_audio')) {
		mainSliderElem.querySelectorAll('.am-media-slide_audio').forEach(item => {
			const audio = item.querySelector('audio')

			item.addEventListener('click', function () {
				if (audio.paused) {
					playMediaSlide(item, progressBar)
				} else {
					pauseMediaSlide(item, progressBar)
				}
			})
		})
	}
}

function getPercentProgress(player, type = 'video') {
	if (type === 'video') {
		const playerTotalTime = player.getDuration()
		const playerCurrentTime = player.getCurrentTime()

		return (playerCurrentTime / playerTotalTime) * 100
	} else if (type === 'audio') {
		const playerTotalTime = player.duration
		const playerCurrentTime = player.currentTime

		return (playerCurrentTime / playerTotalTime) * 100
	}
}

function openMediaGallery(gallery) {
	gallery.classList.add('am-media_visible')
	disableBodyScroll(gallery)
}

function closeMediaGallery(gallery) {
	var slide = gallery.querySelector('.swiper-slide-active')
	var progressBar = gallery.querySelector('.am-media-progress')

	gallery.classList.remove('am-media_visible')
	pauseMediaSlide(slide, progressBar)
	enableBodyScroll(gallery)
}

function playMediaSlide(slide, progressBar) {
	if (slide.querySelector('audio')) {
		const audio = slide.querySelector('audio')
		audio.play()
		slide.classList.add('am-media-slide_playing')
		slide.classList.remove('am-media-slide_pause')
		progressBar.classList.add('am-media-progress_active')
		audio.progressInterval = setInterval(() => {
			progressBar.style.width = `${getPercentProgress(audio, 'audio')}%`
		}, 500)
	}
}

function pauseMediaSlide(slide, progressBar) {
	if (slide.querySelector('audio')) {
		const audio = slide.querySelector('audio')
		audio.pause()
		slide.classList.remove('am-media-slide_playing')
		slide.classList.add('am-media-slide_pause')
		clearInterval(audio.progressInterval)
	} else if (slide.querySelector('.am-media-slide__video')) {
		const video = slide.querySelector('.am-media-slide__video')
		pause(video)

		progressBar.classList.remove('am-media-progress_active')
		progressBar.style.width = 0
	}
}

function setMediaSlideProgress(slide, progressBar) {
	if (slide.querySelector('iframe')) {
		const video = slide.querySelector('iframe')
		const player = getYPByID(video.dataset.videoId)

		progressBar.style.width = `${getPercentProgress(player)}%`
	} else if (slide.querySelector('audio')) {
		const audio = slide.querySelector('audio')

		progressBar.style.width = `${getPercentProgress(audio, 'audio')}%`
	}
}

function setCursorPositionToElem(elem, e, container = document.body) {
	const rect = container.getBoundingClientRect()
	const cursorX = e.clientX - rect.left
	const cursorY = e.clientY - rect.top
	
	elem.style.top = `${cursorY}px`
	elem.style.left = `${cursorX}px`
}