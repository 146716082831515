import Macy from 'macy';

document.macyList = [];

document.addEventListener('DOMContentLoaded', function () {

    const cardsWrapperDesk3 = document.querySelectorAll('.am-cards_desktop-3:not([data-skip-macy="true"])');
    const cardsWrapperDesk4 = document.querySelectorAll('.am-cards_desktop-4:not([data-skip-macy="true"])');
    const cardsWrapperDeskBigMt = document.querySelectorAll('.am-cards_desktop-big-mt:not([data-skip-macy="true"])');
    const cardsMediaWrapperDesk3 = document.querySelectorAll('.am-cards-media_desktop-3:not([data-skip-macy="true"])');

    if (cardsMediaWrapperDesk3.length > 0) {
        cardsMediaWrapperDesk3.forEach(wrap => {
            const macy = Macy({
                container: wrap,
                trueOrder: false,
                waitForImages: false,
                margin: 30,
                columns: 3,
                breakAt: {
                    1070: {
                        columns: 2,
                        margin: {
                            x: 20,
                            y: 20
                        }
                    },
                    525: 1,
                }
            });

            document.macyList.push(macy);
        });
    }

    if (cardsWrapperDesk3.length > 0) {
        cardsWrapperDesk3.forEach(wrap => {
            const macy = Macy({
                container: wrap,
                trueOrder: true,
                waitForImages: false,
                margin: 30,
                columns: 3,
                breakAt: {
                    1070: {
                        columns: 2,
                        margin: {
                            x: 20,
                            y: 30
                        }
                    },
                    525: 1,
                }
            });

            document.macyList.push(macy);
        });
    }

    if (cardsWrapperDesk4.length > 0) {
        cardsWrapperDesk4.forEach(wrap => {
            const macy = Macy({
                container: wrap,
                trueOrder: true,
                waitForImages: false,
                margin: 30,
                columns: 4,
                breakAt: {
                    1200: {
                        columns: 2,
                        margin: {
                            x: 20,
                            y: 30
                        }
                    },
                    630: 1,
                }
            });

            document.macyList.push(macy);
        });
    }

    if (cardsWrapperDeskBigMt.length > 0) {
        cardsWrapperDeskBigMt.forEach(wrap => {
            const macy = Macy({
                container: wrap,
                trueOrder: true,
                waitForImages: false,
                margin: {
                    x: 30,
                    y: 110
                },
                columns: 3,
                breakAt: {
                    1350: {
                        columns: 2
                    },
                    950: {
                        columns: 1,
                        margin: {
                            x: 0,
                            y: 120
                        }
                    },
                    630: {
                        columns: 1,
                        margin: {
                            x: 0,
                            y: 85
                        }
                    },
                }
            });

            document.macyList.push(macy);
        });
    }

});
