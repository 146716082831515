import Form from './_validate.js';

document.addEventListener('DOMContentLoaded', function () {
	if (document.querySelector('form')) {
		let $form_response_markup = `
			<div class="am-form-answer">
							<div class="am-form-answer__info">
									<h5 class="am-form-answer__title">
											— Ваше сообщение успешно отправлено!
									</h5>
									<div class="am-form-answer__text">
											Вы можете отправить еще одно
									</div>
									<a href="" class="am-form-answer__button am-btn">
											Отправить  еще
									</a>
							</div>
					</div>
			`;

		function checkIsLabelActive(input) {
			const label = input.parentElement.querySelector('.am-input__label')

			if (input.value || input === document.activeElement) {
				label.classList.add('am-input__label_active')
			} else {
				label.classList.remove('am-input__label_active')
			}
		}

		function inputOnErrorHandler(input) {
			const wrapper = input.parentElement
			const notice = wrapper.querySelector('.am-input__notice')
			wrapper.classList.remove('am-input_correct')
			wrapper.classList.add('am-input_error')

			notice.classList.add('am-input__notice_visible')
			clearTimeout(notice.timeout)
			notice.timeout = setTimeout(() => {
				notice.classList.remove('am-input__notice_visible')
			}, 5000)
		}

		function inputOnCorrectHandler(input) {
			const wrapper = input.parentElement
			const notice = wrapper.querySelector('.am-input__notice')
			wrapper.classList.add('am-input_correct')
			wrapper.classList.remove('am-input_error')
			notice.classList.remove('am-input__notice_visible')
		}

		function submitFeedbackFormHandler(event) {
			const form = event.target

			if (form.querySelector('.am-form_feedback')) {
				event.preventDefault();
				var query_params = Array.from(
					new FormData(form),
					e => e.map(encodeURIComponent).join('=')
				).join('&');

				query_params += '&web_form_submit=Y&web_form_apply=Y';

				$.ajax({
					url: form.action,
					type: 'POST',
					data: query_params,
					complete: function () {
						document.querySelector('#feedback-form').innerHTML = $form_response_markup;
					},
				});
			}
		}

		document.querySelectorAll('.am-input__body').forEach(input => {
			checkIsLabelActive(input)
			input.addEventListener('change', () => checkIsLabelActive(input))
			input.addEventListener('focusin', () => checkIsLabelActive(input))
			input.addEventListener('focusout', () => checkIsLabelActive(input))
		})

		const form = new Form(document.querySelectorAll('form'), {
			focusValidate: true,
			classes: {
				empty: 'input_empty',
				error: 'input_error',
				correct: 'input_correct'
			},
			fields: [
				{
					selector: '.am-js__name',
					maxLength: 32,
					realTimeRegExp: 'text',
					realTime: true,
					required: true
				},
				{
					selector: '.am-js__phone',
					maxLength: 32,
					realTimeRegExp: 'phone',
					realTime: true,
					required: true,
					mask: '+* (***) ***-**-**',
					regExp: 'phone'
				},
				{
					selector: '.am-js__email',
					maxLength: 32,
					realTimeRegExp: 'email',
					realTime: true,
					required: true,
					regExp: 'email'
				},
				{
					selector: '.am-js__message',
					realTimeRegExp: 'text',
					realTime: true,
					required: true,
				},
				{
					selector: '.am-js__search',
					realTimeRegExp: 'text',
					realTime: true,
					required: true,
				},
			]
		});

		form.on('error', inputOnErrorHandler)
		form.on('empty', inputOnErrorHandler)
		form.on('correct', inputOnCorrectHandler)
		form.on('submit', submitFeedbackFormHandler)
	}
});