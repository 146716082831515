const tag = document.createElement('script');

tag.src = "https://player.vimeo.com/api/player.js";
const firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

const vimeoContainers = document.querySelectorAll('.vimeo-video');
const vimeoPlayers = [];

if (vimeoContainers) {
  window.addEventListener('load', function () {
    initVimeoPlayers(vimeoContainers)
  })
}

export const initVimeoPlayers = (videos, params = {}, events = []) => {
  videos.forEach((item) => {
    const videoId = item.dataset.videoId;
    const elemId = item.id;

    if (!getVPByID(videoId)) {
      const player = new Vimeo.Player(elemId, {
          id: videoId
      });

      player.amPlayerId = videoId;
      player.amType = 'vimeo';

      if(events) {
        events.forEach(event => {
          player.on(event.name, event.func)
        })
      }

      vimeoPlayers.push(player);
    }
  });
}

export const getVPByID = (id) => (
    vimeoPlayers.filter((item) => item.amPlayerId === id)[0]
);

