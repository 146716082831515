import {getYPByID} from "./youtubeApi";
import {getVPByID} from "./vimeoApi";

export function pause(video) {
    const videoId = video.dataset.videoId;
    if (video.classList.contains('youtube-video')) {
        const player = getYPByID(videoId);

        if (player) {
            player.pauseVideo();
        }
    } else {
        const player = getVPByID(videoId);

        if (player) {
            player.pause();
        }
    }
}

export function play(video) {
    const videoId = video.dataset.videoId;
    if (video.classList.contains('youtube-video')) {
        const player =  getYPByID(videoId);

        if (player) {
            player.playVideo();
        }
    } else {
        const player = getVPByID(videoId);

        if (player) {
            player.play();
        }
    }
}