import Swiper from '../../node_modules/swiper/js/swiper.js'

const mainClass = 'am-photo-slider';
const modifierBigItems = `${mainClass}_big-items`;

document.addEventListener('DOMContentLoaded', function() {
	const sliders = document.querySelectorAll(`.${mainClass}`); 
	
	if (sliders.length == 0)
		return false;

	sliders.forEach(slider => {

		const slides = slider.querySelectorAll(`.${mainClass}__slide`);

		if (slides.length == 1) {
			slider.classList.add(`${mainClass}_single`);
			return false
		}

		const sliderHalf = new Swiper(slider.querySelector('.swiper-container'), {
			speed: 450,
			simulateTouch: false,
			loop: true,
			slideActiveClass: `${mainClass}__slide_active`,
			slideDuplicateActiveClass: `${mainClass}__slide_active`,
			autoplay: {
				delay: 5000
			},
			navigation: {
				nextEl: slider.querySelector('.swiper-arrow-right'),
				prevEl: slider.querySelector('.swiper-arrow-left'),
			},
			breakpoints: {
				320: {
					slidesPerView: (slider.classList.contains(modifierBigItems)) ? 1.07 : 1.8,
				}, 

				780: {
					slidesPerView: (slider.classList.contains(modifierBigItems)) ? 1.38 : 2.7,
				}
			}
		});
	})
});

