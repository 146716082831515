import PerfectScrollbar from 'perfect-scrollbar'

document.addEventListener('DOMContentLoaded', function() {
	if (document.querySelector('.am-detail')) {
		const detailPage = document.querySelector('.am-detail');

		// Обертка таблиц для скролла
		if (detailPage.querySelector('table')) {
			detailPage.querySelectorAll('table').forEach(table => {
				const wrapper = document.createElement('div')
				wrapper.classList.add('am-detail__table-wrapper')

				table.parentElement.insertBefore(wrapper, table.nextElementSibling)
				wrapper.append(table)

				const ps = new PerfectScrollbar(wrapper, {
					wheelSpeed: 2,
					wheelPropagation: true,
					minScrollbarLength: 20
				});
			})
		}
	}
})